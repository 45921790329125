import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./Styles.css";
import Home from "./components/Home";
import PhoneSignUp from "./components/PhoneSignUp";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Header from "./components/Header/Header";
import SignUp from "./components/SignUp";
import Footer from "./components/Footer/Footer";
import Subscriptions from "./components/Subscriptions";
import ChangeSubscriptions from "./components/ChangeSubscriptions";
import ContactUs from "./components/ContactUs";
import TermsandConditions from "./components/TermsandConditions";
import AboutUs from "./components/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Shopping from "./components/Shopping";
import Deals from "./components/Deals";
import Survey from "./components/Survey";
import ClaimPage from "./components/ClaimPage";
import OrderID from "./components/OrderID";
import SubNew from "./components/SubNew";
import RefundPolicy from "./components/RefundPolicy";
import Checkout from "./components/Checkout";
import Surveylist from "./components/Surveylist";
import SurveyDetails from "./components/SurveyDetails";





// //////

import Login from "./components/Login";
import CommonHeader from "./components/Header/CommonHeader";
import MyAccountStatus from "./components/MyAccountStatus";
// import ChangeAddress from "./components/ChangeAddress";
// import Subscriptions from "./components/Subscriptions";
import Winnings from "./components/Winnings";
import Logout from "./components/Logout";
import ProfilePicModal from "./components/ProfilePicModal";
import Dashboard from "./components/Dashboard";
// import ChangeSubscriptions from "./components/ChangeSubscriptions";
// import ChangeAadharPan from "./components/ChangeAadharPan";
// import ContactUs from "./components/ContactUs";
// import TermsandConditions from "./components/TermsandConditions";
// import AboutUs from "./components/AboutUs";
// import PrivacyPolicy from "./components/PrivacyPolicy";

import Testone from "./components/Testone";
import GameStepTwo from "./components/GameStepTwo";
import LoginRedirectPage from "./components/LoginRedirectPage";
import Identity from "./components/Identity";
// import Shopping from "./components/Shopping";
// import Deals from "./components/Deals";
// import Survey from "./components/Survey";
// import ClaimPage from "./components/ClaimPage";
// import OrderID from "./components/OrderID";
// import SubNew from "./components/SubNew";
// import RefundPolicy from "./components/RefundPolicy";
// import Checkout from "./components/Checkout";
import ConfirmPage from "./components/ConfirmPage";
// import Surveylist from "./components/Surveylist";
// import SurveyDetails from "./components/SurveyDetails";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("user")
  );

 

  const handleLogin = (token) => {
    localStorage.setItem("user", token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userphonenumber");
    localStorage.removeItem("_grecaptcha");
    localStorage.removeItem("gridcount");
    localStorage.removeItem("contest_name");
    localStorage.removeItem("game_demo_link");
    localStorage.removeItem("sponcer_name");
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div>
        <div className="min-height-1">
          <Header
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
          />
          <UserAuthContextProvider>
            <Routes>
              <Route path="/home" element={isAuthenticated ? <Home /> : <Navigate to="/" />}/>
              <Route path="/" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/phonesignup"
               element={
                  isAuthenticated ? (
                    <Navigate to="/home" />
                  ) : (
                    <PhoneSignUp handleLogin={handleLogin} />
                  )}/>3
                                                                                                                                    

              <Route
                path="/SignUp"
                element={    
                  isAuthenticated ? (
                    <Navigate to="/home" />
                  ) : (
                    <SignUp handleLogin={handleLogin} />
                  )
                }
              />

              <Route path="/MyAccountStatus" element={  isAuthenticated ? <MyAccountStatus /> : <Navigate to="/" />  }/>
              <Route path="/Winnings" element={isAuthenticated ? <Winnings /> : <Navigate to="/" />}/>
              <Route path="/ProfilePicModal" element={ isAuthenticated ? <ProfilePicModal /> : <Navigate to="/" />  }/>
              <Route path="/Dashboard"element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}/>
              {/* <Route path="/ChangeAadharPan"element={  isAuthenticated ? <ChangeAadharPan /> : <Navigate to="/" />  }/> */}
              <Route path="/TermsandConditions" element={<TermsandConditions />}/>
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/Shopping" element={<Shopping />} />
              <Route path="/Deals" element={<Deals />} />
              <Route path="/survey-list" element={<Surveylist />} />
              <Route path="/Survey-details/:id/:cname" element={<SurveyDetails/>} />
              <Route path="/survey" element={<Survey />} />
              <Route path="/Subscriptions" element={<Subscriptions />} />
              <Route path="/claim-page/:unq_id"  element={<ClaimPage />} />

              {/* <Route path="/claim-page/:unq_id" element={isAuthenticated ? <ClaimPage/> :<Navigate to="/phonesignup"/> } /> */}

              <Route path="/order-id/:unq_id" element={<OrderID />} />
              <Route path="/confirm-page/:unq_id" element={<ConfirmPage />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/ContactUs" element={<ContactUs />} />
              <Route path="/SubNew" element={<SubNew />} />
              <Route path="/RefundPolicy" element={<RefundPolicy />} />
              <Route path="/Checkout/:unq_id" element={<Checkout />} />


              {/* <Route path="/Checkout/:unq_id" element={isAuthenticated? <Checkout/> : <Navigate to="/phonesignup"/>} /> */}


              <Route path="/ChangeSubscriptions"element={<ChangeSubscriptions />}/>
              <Route path="/getsubscriptionvalues/:subscription_level/:subscription_type"element={<ChangeSubscriptions />}/>
              <Route path="/Testone" element={<Testone />} />
              <Route path="/GameStepOne"/>
              <Route path="/GameStepTwo" element={ isAuthenticated ? <GameStepTwo /> : <Navigate to="/" />  }/>

              <Route path="/LoginRedirectPage" element={<LoginRedirectPage />} />
              <Route path="/identity" element={<Identity />} />

              {/* <Route
                path="/Gamep"
                element={
                  <>
                    <CommonHeader />
                    {isAuthenticated ? <Gamep /> : <Navigate to="/" />}
                  </>
                }
              /> */}


             

              {/* <Route
                path="/SortingStarGame"
                element={
                  <>
                    <CommonHeader />
                    {<BoxPizzaGame />}
                  </>
                }
              /> */}
              {/* <Route
                path="/ChangeAddress"
                element={
                  isAuthenticated ? <ChangeAddress /> : <Navigate to="/" />
                }
              /> */}

            </Routes>
          </UserAuthContextProvider>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
