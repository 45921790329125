import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Styles/SliderHome1.css";
import PostImg1 from "./images/post-img-1.png";

import { baseURL, getAllResultNoTocken } from "../services/Aut.post.js";

function HomeSlider() {
  const [allProdcts, setAllProdcts] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllResultNoTocken("tockens-banners");
        setAllProdcts(data);
        console.log(data.length);
      } catch (error) {
        // Handle error if needed
      }
    }; 

    fetchData();
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="slider-home-1">
        <Slider {...settings}>
          {allProdcts.length > 0 ? (
            allProdcts.map((product, index) => (
              <div>
                <div className="slider-img-1">
                  <a href={product.banner_link}>
                    <img
                      src={
                        product.banner_image
                          ? `${baseURL}/${product.banner_image}`
                          : PostImg1
                      }
                      alt={product.banner_text || "product"}
                    />
                  </a>
                </div>
              </div>
            ))
          ) : (
            <div>Loading products...</div>
          )}
        </Slider>
      </div>
    </>
  );
}

export default HomeSlider;
