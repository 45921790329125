import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useUserAuth } from "../context/UserAuthContext";
import "./Styles/PhoneSignUp.css";
import axios from "axios";
import { baseURL } from "../services/Aut.post.js";
import LogoBigbonanza from "./images/logo-2.png";
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from "../firebase"; // Import from firebase.js

const PhoneSignUp = ({ handleLogin }) => {
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const navigate = useNavigate();
  const [loadingSendOTP, setLoadingSendOTP] = useState(false);
  const [loadingVerifyOTP, setLoadingVerifyOTP] = useState(false);

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible',
          'callback': (response) => {
            // reCAPTCHA solved, allow getOtp to be called
          }
        }, auth);
      } catch (error) {
        console.error("Error initializing RecaptchaVerifier:", error);
        setError("Error initializing RecaptchaVerifier");
      }
    }
  }, []);

  const getOtp = async (e) => {
    e.preventDefault();
    console.log(number);
    setError("");
    setLoadingSendOTP(true);
    console.log("Number Length", number.toString().length);
    if (
      number === "" ||
      number === undefined ||
      number.toString().length < 13 ||
      number.toString().length > 15
    ) {
      // This is only for India
      setLoadingSendOTP(false); // Reset loading state
      return setError("Please enter a valid phone number");
    }
    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(auth, number, appVerifier);
      setResult(confirmationResult);
      setFlag(true);
      setLoadingSendOTP(false);
    } catch (err) {
      setLoadingSendOTP(false);
      setError(err.message);
      console.error("Error during getOtp", err);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");

    if (otp === "" || otp === null) return;
    try {
      await result.confirm(otp);
      // Set auth user token
      axios
        .post(`${baseURL}/userapi/settoken/`, {
          phone_number: number,
        })
        .then((response) => {
          if (response.data) {
            if (response.data.signup_status === 0) {
              localStorage.setItem(
                "userLoginStatus",
                JSON.stringify(response.data)
              );
              localStorage.setItem("userphonenumber", JSON.stringify(number));
              console.log(response.data.user_referal_code);

              // Check user has a referal or not

              navigate(`/SignUp?ref=${response.data.user_referal_code}`);
            } else if (response.data.signup_status === true) {
              localStorage.setItem("user", JSON.stringify(response.data));
              const token = JSON.stringify(response.data);
              
              window.postMessage({ type: 'USER_TOKEN', token: response.data.access }, '*');
              handleLogin(token);
              navigate("/Dashboard");
              window.location.href="/Dashboard"
            } else {
              console.error(
                "Unexpected signup_status:",
                response.data.signup_status
              );
            }
          }

          return response.data;
        });
    } catch (err) {
      if (err.code === "auth/invalid-verification-code") {
        setError("Invalid OTP. Please enter a valid OTP.");
      } else {
        setError("An error occurred. Please try again.");
      }
      console.error("Error during verifyOtp", err);
    }
  };

  return (
    <>
      <div className="Login-Bg">
        <div id="recaptcha-container"></div>
        <div className="Login-wrap">
          <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
            <div className="Login-Header-Text">
              <h2>Login</h2>
              <h6>
                <img src={LogoBigbonanza} alt="LogoBigbonanza" />
              </h6>
              <div className="closePopup1">
                <Link to="/">
                  <Button variant="secondary">X</Button>
                </Link>
              </div>
            </div>
            <div className="Login-Content-wrap">
              {error && <Alert variant="danger">{error}</Alert>}

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <PhoneInput
                  // International
                  defaultCountry="IN"
                  value={number}
                  onChange={setNumber}
                  placeholder="Enter Phone Number"
                  maxLength="11"
                  countryCallingCodeEditable={false}
                  autoFocus
                />
              </Form.Group>
              <div className="button-right">
                <Button
                  type="submit"
                  className="button-2 w-100"
                  disabled={loadingSendOTP || loadingVerifyOTP}
                >
                  {loadingSendOTP ? "Please wait..." : "Generate OTP"}
                </Button>
              </div>
            </div>
          </Form>

          <Form onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>
            <div className="Login-Header-Text">
              <h2>Phone Verification</h2>
              <h6>
                <img src={LogoBigbonanza} alt="LogoBigbonanza" />
              </h6>
              <h6>Please enter the OTP that was sent to your phone number.</h6>
              <div className="closePopup1">
                <Link to="/">
                  <Button variant="secondary">X</Button>
                </Link>
              </div>
            </div>
            <div className="Login-Content-wrap">
              {error && <Alert variant="danger">{error}</Alert>}
              <Form.Group className="mb-3" controlId="formBasicOtp">
                <p>OTP</p>
                <Form.Control
                  type="otp"
                  placeholder="Enter OTP"
                  onChange={(e) => setOtp(e.target.value)}
                  autoFocus
                />
              </Form.Group>
              <div className="button-right">
                <Button type="submit" className="button-2 w-100">
                  Verify OTP
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PhoneSignUp;
