
import React from 'react';
import "./Styles/ExtraOffers.css";

function OrderID() {
  
  const handleClick = () => {
    const redirectUrl = `/`;
  
    window.location.href = redirectUrl;
  };




  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
      
          <div className="claim-page-bg">
           
            <h4> Your order  is successfully submitted</h4> 
            <h3>
       
            </h3>
            <button class="button-orange-1 w-80" onClick={handleClick}>Back to Home</button>
          </div>
     
          
        </div>
      </div>
    </>
  );
}

export default OrderID;
