import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import InnerBanner from "./InnerBanner";
import "./Styles/Profile.css";
import ProfileImg from "./images/profile-img.png";
import ActiveImg from "./images/active.png";
import { getHomeDetails, baseURL } from "../services/Aut.post.js";
import ProfileEditIcon from "./images/profile-edit.png";


const Home = () => {
  const accessToken = JSON.parse(localStorage.getItem("user"));

  const btoken = accessToken.access;
  //image upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [homeDetailsGet, setHomeDetailsGet] = useState([]);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // setSelectedFile(file);
    if (!file) {
      alert("Please select a file.");
      return;
    }

    //set file upload on change
    const formData = new FormData();
    formData.append("file", file);
    fetch(`${baseURL}/userapi/getimageuploadview/`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${String(btoken)}`,
        //'Content-Type': 'application/json',
        // Add other headers as needed
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Upload successful:", data);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Upload error:", error);
      });

    //end file uplaod
  };
  const handleUpload = () => {
    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    fetch(`${baseURL}/userapi/getimageuploadview/`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${String(btoken)}`,
        //'Content-Type': 'application/json',
        // Add other headers as needed
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Upload successful:", data);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Upload error:", error);
      });
  };
  //end image upload

  const { logOut, user } = useUserAuth();

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  //User get Details

  const [userData, setUserData] = useState();
  const getAllPrivatePosts = async () => {
    try {
      const response = await fetch(`${baseURL}/userapi/userdetails/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${String(btoken)}`,
          "Content-Type": "application/json",
          // Add other headers as needed
        },
      });
      // if (!response.ok) {
      //   throw new Error(`HTTP error! Status: ${response.status}`);
      // }
      const data = await response.json();
      console.log(data);
      return data; // You can return the data or perform other actions
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllPrivatePosts();
        setUserData(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchHomeData = async () => {
      try {
        const data = await getHomeDetails(btoken);
        setHomeDetailsGet(data);
        console.log("User Account Status:", data);
      } catch (error) {
        console.log("User Account Status:", error);
      }
    };

    fetchHomeData();
    fetchData();
  }, []);

  return (
    <>
      <InnerBanner />
      <div className="profile-bg-1">
        <div className="wrapper">
          <h1>My Profile</h1>
          <div className="profile-sec-main">
            <div className="profile-sec-1">
              {userData &&
                userData.map((userItem) =>
                  userItem.userimage ? (
                    <img
                      className="ProfilePic"
                      src={`${baseURL}${userItem.userimage}`}
                      alt="User Image"
                    />
                  ) : (
                    <img src={ProfileImg} alt="" />
                  )
                )}

              <div className="profileActions">

                <div className="image-upload">
                  <label for="file-input">
                    <img src={ProfileEditIcon} alt="ProfileEditIcon" />
                  </label>

                  <input
                    id="file-input"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>


                {selectedFile && <p>Selected File: {selectedFile.name}</p>}
              </div>
            </div>

            <div className="profile-sec-2">
              {userData &&
                userData.map((userItem) => (
                  <div className="verification">
                    {userItem.admin_approval === true && (
                      <div>
                        Verification Status:
                        <div>
                          <span>
                            <img src={ActiveImg} alt={ActiveImg} />
                            Verified
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              {userData &&
                userData.map((userItem) => (
                  <div key={userItem.id}>
                    <h4>{userItem.first_name}</h4>

                    <hr></hr>
                    <h5>{userItem.phone_number}</h5>
                    <h5>{userItem.email}</h5>
                  </div>
                ))}
              <div className="profile-info-wrap">
                <div className="piw-1">
                  No of tokens: {homeDetailsGet.tockenslist}
                </div>
                <div className="piw-1">User Level: Clubby</div>
                <div className="piw-1">Subscription Status: Clubby</div>
                <div className="piw-1">
                  No of Referrals: {homeDetailsGet.getcountReferrals}
                </div>
                <div className="piw-1 w-100">
                  Current Address: {homeDetailsGet.getAddres}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
