import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link } from "react-router-dom";

import { baseURL, getDealsAll } from "../services/Aut.post.js";

function ExtraOffers() {
  const [allDeals, setAllDeals] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDealsAll();
        setAllDeals(data);
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="extra-offer-wrap">
        <div className="extra-offer-flex">
          {allDeals.length > 0 ? (
            allDeals.map((value, index) => (
              <div className="extra-offer-1">
                <img
                  src={
                    value.marketplace_image
                      ? `${baseURL}/${value.marketplace_image}`
                      : amazon
                  }
                  alt={value.marketplace_image || "product"}
                  style={{ height: "80px" }}
                />

                <h6>
                  <Link to={`/claim-page/${value.unq_id}`}>
                    Earn {value.total_tockens} Tockens
                  </Link>
                </h6>
              </div>
            ))
          ) : (
            <div>loading</div>
          )}
        </div>
      </div>
    </>
  );
}

export default ExtraOffers;
