import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import { Link } from "react-router-dom";
import {  getAllResult } from "../services/Aut.post.js";
import "./SurveyDetails.jsx"

function Deals() {
  const [allDeals, setAllDeals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllResult("surveystwo");
        console.log("DATA:", data); // Log the data
        if (data && data.surveys) {
          setAllDeals(data.surveys);
        } else {
          console.error("Invalid data format:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="top-space-1">
      <div className="extra-offer-wrap">
        <h1>Survey's</h1>
        <div className="extra-offer-flex">
          {allDeals.length > 0 ? (
            allDeals.map((value, index) => (
              <div className="extra-offer-1" key={index}>
                <div>{value.title}</div>
                 <h6>
                  <Link to={`/Survey-details/${value.id}/${value.collection_name}`}>
                    Open Survey 
                  </Link>
                </h6>
                {/* <button type = "button">fggh</button> */}
              </div>

            ))
          ) : (
            <div>
              <h4 style={{ color: "#000" }}>loading</h4>
            </div>
          )}
        </div>
      </div>
      {/* <button>fhgf</button> */}
    </div>
  );
}

export default Deals;
