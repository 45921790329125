
import React, { useEffect, useState } from 'react';
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link, useParams } from "react-router-dom";
import { baseURL, getDeal } from "../services/Aut.post.js";
function OrderID() {
  const { unq_id } = useParams();
  
  const [getDealOne, setGetDealOne] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDeal(unq_id);
        setGetDealOne(data)
       
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
}, []);

const [orderId, setOrderId] = useState("");

// Handle input change
const handleChange = (e) => {
 
  setOrderId(e.target.value);
};

const validate = () => {
  const newErrors = {};
  if (!orderId){
    newErrors.orderId = 'Application No is required';
  } 
  return newErrors;
};
const [errors, setErrors] = useState({});
  // Handle form submission
  const handleSubmit = async (e) => {
  
 
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
   
      setErrors(validationErrors);
    } else {
      console.log(orderId)

      const redirectUrl = `/confirm-page/${unq_id}`;
    
      // Redirect to the constructed URL
      window.location.href = redirectUrl;

    }

  };


  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
      
          <div className="claim-page-bg">
            <div className="claim-page-header">
              {/* <img src={amazon} alt="amazon" /> */}

              <img 
                src={getDealOne.marketplace_image ? `${baseURL}/${getDealOne.marketplace_image}` : amazon} 
                alt={getDealOne.unq_id || "product"} 
              />
            </div>
            {errors.orderId && <div className="error">{errors.orderId}</div>}
            <h4>Enter your Order ID or Application No.</h4>
            <h3>
              <input
                type="text"
                placeholder="Order ID/Application No."
                className="text-field-1"
                onChange={handleChange}
              ></input>
            </h3>
            <button class="button-orange-1 w-80" onClick={handleSubmit}>Submit</button>
          </div>
     
          <div className="backhome">
            <Link to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderID;
