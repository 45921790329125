import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner";
import { useParams } from "react-router-dom";
import "./Styles/ContactUs.css";
import { Form } from "react-bootstrap";
import { baseURL, getProduct } from "../services/Aut.post.js";

function Checkout() {
  const [successMessage, setSuccessMessage] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [btoken, setBtoken] = useState("");
  const [getDealOne, setGetDealOne] = useState("");
  const { unq_id } = useParams();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access) {
      setAccessToken(user);
      setBtoken(user.access);
    } else {
      // Handle the case where there is no user or access token
      console.error("No access token found");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getProduct(unq_id);
        setGetDealOne(data);

        console.log(data);
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
  }, [unq_id]);

  const [formData, setFormData] = useState({
    productid: unq_id,
    fullName: "",
    email: "",
    phone: "",
    address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [errors, setErrors] = useState({});
  
  // Validate form data
  const validate = () => {
    const newErrors = {};
    if (!formData.fullName) {
      newErrors.fullName = "Full Name is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number is invalid";
    }
    if (!formData.address) newErrors.address = "Address is required";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      console.log(formData);
      try {
        const response = await fetch(
          "http://127.0.0.1:8000/tockens_api/order-address/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${String(btoken)}`,
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          const redirectUrl = `/confirm-page/${unq_id}`;
          window.location.href = redirectUrl;
        } else {
          alert("Error submitting form");
        }
      } catch (error) {
        // Handle network errors
        alert("Network error");
      }
    }
  };

  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <h1>Shopping</h1>

          <div className="checkout-wrap">
            <div className="checkout-1">
              <div className="checkout-items-wrap">
                <div className="checkout-items-1">
                  <img
                    src={`${baseURL}/media/${getDealOne.product_image}`}
                    alt={getDealOne.product_title || "product"}
                  />
                </div>
                <div clgetDealOneassName="checkout-items-2">
                  <h4>{getDealOne.product_title}</h4>
                  <h5>{getDealOne.product_tockens} Tockens</h5>
                  <p>Shipping Charges - ₹100 
                    
                    {/* {getDealOne.charges} */}
                    
                    </p>
                </div>
              </div>
            </div>
            <div className="checkout-2">
              <h3>Shipping Address</h3>
              <div>
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
              </div>

              <div>
                <Form onSubmit={handleSubmit}>
                  <div className="checkout-form-wrap">
                    <div className="reg-1 w-100">
                      <input
                        type="text"
                        name="fullName"
                        className="text-f-1"
                        placeholder="Full Name"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                      {errors.fullName && (
                        <div className="error">{errors.fullName}</div>
                      )}
                    </div>

                    <div className="reg-1 w-100">
                      <input
                        type="email"
                        name="email"
                        className="text-f-1"
                        placeholder="E-mail"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>

                    <div className="reg-1 w-100">
                      <input
                        type="text"
                        name="phone"
                        className="text-f-1"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && (
                        <div className="error">{errors.phone}</div>
                      )}
                    </div>
                    <div className="reg-1 w-100">
                      <textarea
                        name="address"
                        className="text-f-1"
                        placeholder="Full Address "
                        value={formData.address}
                        onChange={handleChange}
                      ></textarea>
                      {errors.address && (
                        <div className="error">{errors.address}</div>
                      )}
                    </div>

                    <div className="reg-1 w-100 text-center">
                      <button className="btn-2" type="submit">Confirm & Pay Now</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Checkout;
